import React from "react";

import Layout from "../layout/index";

import Seo from "../components/seo/index";

import Fbhbreadcrumbpage from "../components/about/fbh-breadcrumbs";

import Fbhpagecontent  from "../components/about/fbhpagecontent";

import Fbhcounttext  from "../components/about/fbhcount";

import Aboutproject  from "../components/about/about-project";

import Socialiconright from "../components/home/social-icon-right";

const FbH = () => {
  return (
    <div>
      <Seo title="Green Heartfulness Run 2024 - FbH" />
      <Layout isSticky>
        <Fbhbreadcrumbpage />
        <Socialiconright />
        <Fbhpagecontent  />
        <Fbhcounttext />
        <Aboutproject />
      </Layout>
    </div>
  );
};

export default FbH;
