import React from "react";

import zscaler from "../../assets/images/about/fbh/zscaler.png";

import bosch from "../../assets/images/about/fbh/bosch.png";


import tree from "../../assets/images/about/fbh/tree.png";


import forestnursuries from "../../assets/images/about/fbh/forestnursuries.png";


const Aboutproject = () => {
  return (
    <div className="aboutprject-wrpper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3 className="title-heading"> PROJECTS </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="forest-plant-details">
              <img src={zscaler} className="img-fluid" alt="GKR" />
              <h4>
                {" "}
                Zscaler planting 15000 saplings to celebrate 15 years of
                excellence
              </h4>
              <p>
                {" "}
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="forest-plant-details">
              <img src={bosch} className="img-fluid" alt="bosch" />
              <h4> Plantation drive with Bosch Global Software Technologies</h4>
              <p>
                {" "}
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the
              </p>
            </div>
          </div>

          <div className="col-md-3">
            <div className="forest-plant-details">
              <img src={tree} className="img-fluid" alt="GKR" />
              <h4> Tree rescue via translocation</h4>
              <p>
                {" "}
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the
              </p>
            </div>
          </div>

          <div className="col-md-3">
            <div className="forest-plant-details">
              <img src={forestnursuries} className="img-fluid" alt="GKR" />
              <h4> Forests By Heartfulness Network of 18 nurseries</h4>
              <p>
                {" "}
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutproject;
