import React from "react";

const Fbhcounttext = () => {
  return (
    <div className="fbhcounttext-wrapper">
      <div className="first-green">
        <h3> 1,029,891 </h3>
        <p> Trees Planted So Far</p>
      </div>
      <div className="second-green">
        <h3>249,412 </h3>
        <p> Trees Planted So Far</p>
      </div>
      <div className="last-green">
        <h3> 1,029,891 </h3>
        <p> Trees Planted So Far</p>
      </div>
    </div>
  );
};

export default Fbhcounttext;
