import React from "react";

import fbhbreadcrumb from "../../assets/images/about/fbh/fbh-breadcrumb.png";

export default function Fbhbreadcrumbpage() {
  return (
    <div className="breadcrumbsbanner-wrapper inner-pages">
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        <img
          className="img-fluid"
          src={fbhbreadcrumb}
          width="100%"
          alt="Clock logo"
        />
        <h1 className="breadcrumb-title"> FORESTS BY HEARTFULNESS </h1>
      </div>
    </div>
  );
}
