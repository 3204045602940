import React from "react";

import daaji from "../../assets/images/about/fbh/daaji.png";

const Fbhpagecontent = () => {
  return (
    <div className="fbhpage-wrapper subpages pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <img src={daaji} className="img-fluid" alt="GKR" />
          </div>
          <div className="col-md-8">
            <h3 className="title-heading"> FORESTS BY HEARTFULNESS </h3>
            <p>
              {" "}
              Our primary goal is to plant at-least 30 million native and
              endemic trees across India by 2025.
            </p>
            <p>
              {" "}
              In order to achieve this goal, a team of forestry, agro-forestry
              and climate experts are guiding the project and will continue to
              do so for the next 5 years. Under their guidance, as a first step,
              nurseries are being developed in 18 cities across the country.{" "}
            </p>
            <p className="pt-1">
              {" "}
              Saplings of carefully selected tree and plant species are raised
              in these nurseries during non-plantation season every year between
              October & May. Mass plantation of these saplings takes place every
              year between June – September (monsoon) and Jan – Feb (late
              winter). This will be carried out by the Heartfulness volunteers,
              farmers, NGOs, school children and general public. Planted
              saplings will be effectively monitored and nurtured by providing
              optimum conditions for growth to ensure high survival rate.
            </p>{" "}
            <p>
              {" "}
              FBH has already conducted a successful mass plantation drive in
              the year 2019 covering 64 cities – 64,000 trees – in one day
              through its volunteers and its partnering organizations. {/*For more
              information plz visit our website:*/}
              <br></br><br></br>
              <div className="know_more_btn_container">
             <a href="https://heartfulness.org/forests/" className="know_more_btn btn-white" target="_blank"  rel="noreferrer"> Know more about Forests
              by Heartfulness </a>
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fbhpagecontent;
